import React from 'react'

import '../assets/fonts/fonts.css'
import '../assets/fonts/social.css'
import '../styles/modal.css'
import '../styles/burgerMenu.css'

import { ThemeProvider, Global, css } from '@emotion/react'
import { theme } from '../styles'
import i18n from '../../config/i18n'

const globalStyle = css`
  * {
    box-sizing: border-box;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font-family: ${theme.fontFamily};
    margin: 0;
    padding: 0;
    background: #111111;
  }
  h2, h3, h4, h5, h6 {
    font-weight: 300;
  }
  h1 {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 40px;
    letter-spacing: 3px;
  }
  h3 {
    font-size: 36px;
    font-weight: 600;
    text-align:center;
    padding:0 100px;
  }
  h3.magic {
    font-family: "SummerHearts";
  }
`

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
        <React.Fragment>
          {children}
        </React.Fragment>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }
